// export const baseUrl = "http://localhost:8000"
export const baseUrl = "https://backendapi.homesforsalemedia.com"
// export const baseUrl = "http://100.24.197.78"
export const login = "/api/token/";
export const verifyToken = "/api/token/verify/";
export const refreshToken = "/api/token/refresh/";
export const syncLocations = "/api/onboard_locations/";
export const syncLocations_v2 = "/api/sync_onboarded_locations/";
export const locationList = "/api/locationList/";
export const globalUsers = "/api/global_users/";
export const userList = "/api/users/";
export const getUser = "/api/user/";
export const campaignList = "/api/campaignList/";
export const fallbackUserList = "/api/campaignFallBackUsers/";
export const fallbackUserCreate = "/api/campaignFallBackUsersCreate/";
export const fallbackUserDelete = "/api/campaignFallBackUsersDelete/";
export const lockCampaignUrl = "/api/lockCampaign/";
export const lockUserUrl = "/api/lockUser/";
export const lockLocationUrl = "/api/lockLocation/";
export const campaignUserList = "/api/campaignUsers/";
export const userUpdate = "/api/userUpdate/";
export const campaignUserUpdate = "/api/campaignUserUpdate/";
export const purchaseList = "/api/purchaseList/";
export const purchaseFormData = "/api/purchaseFormData/";
export const purchaseInitiate = "/api/purchaseInitiate/";
export const purchaseGet = "/api/purchaseGet/";
export const purchaseEdit = "/api/purchaseEdit/";
export const purchasedelete = "/api/purchaseDelete/";
export const purchaseCreate = "/api/createPurchaseOrder/";
export const getLanguageCampaign = "/api/get_language_campaign/";
export const getLanguages = "/api/get_languages/";
export const addNewLanguages = "/api/addNewLanguages/"
export const deleteLanguage = "/api/deleteLanguage/";
export const addNewCampaign = "/api/addNewCampaign/";
export const locationManage = "/api/locationManage/";
export const locationAdd = "/api/validation/";
export const dashboardFilterData = "/api/dashboard_filters/";
export const dashboardReports = "/api/dashboard_reports/";
export const dashboardLeads = "/api/dashboard_leads/";
export const leaderCreate = "/api/leader_create/"
export const teamCreate = "/api/team_create/"
export const userCreate = "/api/user_create/"
export const getTeams = "/api/get_teams/"
export const getLeaders = "/api/get_leaders/"
export const getGhlUsers = "/api/get_ghl_users/"
export const deleteLeader = "/api/delete_leader/"
export const deleteTeam = "/api/delete_team/"
export const leaderLocations = "/api/get_leader_locations/"
export const leaderTeams = "/api/get_leader_teams/"
export const teamLocations = "/api/get_team_locations/"
export const leaderLocationManage = "/api/leaderLocationManage/"
export const leaderTeamManage = "/api/leaderTeamManage/"
export const teamLocationManage = "/api/teamLocationManage/"
export const AppUserList = "/api/CustomAppUserList/"
export const getAppLead = "/api/getCustomAppLead/"
export const get_form_initials = "/api/get_form_initials/"
export const form_submit = "/api/custom_form_submission/"
export const UpdateAppStage = "/api/UpdateAppStage/"
export const AddFollowers = "/api/add_followers/"
export const AddSales = "/api/add_sales/"
export const MoveToFollower = "/api/move_to_followers/"
export const MoveToSales = "/api/move_to_sales/"
export const RemoveFollowerSales = "/api/remove_follower_sales/"
export const updateAssignedUser = "/api/update_assigned_user/"
export const appSubmissionList = "/api/app_submission_list/"
export const appSubmissionListByStage = "/api/app_submission_list_by_stage/"
export const appKanbanUserList = "/api/app_user_list/"
export const createComment = "/api/create_comment/"
export const updateComment = "/api/update_comment/"
export const deleteComment = "/api/delete_comment/"
export const passwordUpdate = "/api/password_update/"
export const UpdateCustomAppLead = "/api/UpdateCustomAppLead/"
export const deleteProfileData = "/api/deleteProfileData/"
export const auditLog = "/api/auditLog/"
export const appSubmission = "/api/app_submission/"

export const createCheckListHeading =  "/api/create_checklist_heading/"
export const updateCheckListHeading = "/api/update_checklist_heading/"
export const deleteCheckListHeading = "/api/delete_checklist_heading/"
export const createCheckList = "/api/create_checklist/"
export const updateCheckList = "/api/update_checklist/"
export const deleteCheckList = "/api/delete_checklist/"
export const getNotifications = "/api/get_notifications/"
export const notificationOpened = "/api/notification_opened/"
export const labelCreate = "/api/label_create/"
export const labelUpdate = "/api/label_update/"
export const labelDelete = "/api/label_delete/"
export const descriptionAdd = "/api/add_description/"

export const getLabel = "/api/get_label/"
export const addLabel = "/api/add_label/"
export const removeLabel = "/api/remove_label/"
export const registeredUserList = "/api/registered_app_user_list/"

export const getPipelines = "/api/get_pipelines/"
export const upsertPipeline = "/api/upsert_pipeline/"
export const deletePipeline = "/api/delete_pipeline/"
export const getOpportunitiesList = "/api/get_opportunity_list/"
export const getOpportunitiesListByStage = "/api/get_opportunity_list_by_stage/"
export const UpdateLeadStage = "/api/UpdateLeadStage/"

export const upserDesignation = "/api/upsert_designation/"
export const getDesignations = "/api/get_designations/"
export const deleteDesignation = "/api/delete_designation/"
export const updateUserDesignation = "/api/update_user_designation/"

export const getCustomFields = "/api/get_custom_fields/"
export const createCustomField = "/api/create_custom_field/"
export const updateCustomField = "/api/update_custom_field/"
export const deleteCustomField = "/api/delete_custom_field/"
export const upserCustomFieldValue = "/api/upsert_custom_field_value/"

export const toggleWatcher = "/api/toggle_watcher/"

export const archiveSubmission = "/api/archive_submission/"
export const unarchiveSubmission = "/api/unarchive_submission/"
export const deleteSubmission = "/api/delete_submission/"
export const getArchivedSubmissionList = "/api/archived_submission_list/"
export const updateDueDate = "/api/update_due_date/"

export const getLeaderBoard = "/api/get_leadborad/"
export const getShoppersLeaderBoard = "/api/get_leadborad/"
export const updateShopperGoal = "/api/update_shopper_goal/"

export const upsertCompany = "/api/upsert_company/"
export const getCompanies = "/api/get_companies/"
export const deleteCompany = "/api/delete_company/"
export const updateUserCompany = "/api/update_user_company/"

export const getAvailabilityslots = "/api/get_availability_slots/"
export const upsertAvailabilitySlot = "/api/upsert_availability_slot/"
export const getAvailableUsers = "/api/get_available_users/"
export const getAllCustomUsers =  "/api/get_all_custom_users/"
export const toggleAvailabilitySlots = "/api/toggle_availability_slots/"